






import NavigationArea from "@/chipply/NavigationArea";
import Vue from "vue";
import Component from "vue-class-component";
import NavigationHost from "../navigation/NavigationHost.vue";
import ProductsSpreadsheetView from "../events/ProductsSpreadsheetView.vue";
import NavigationSide from "../navigation/NavigationSide.vue";
import { Prop } from "vue-property-decorator";

// THIS EXISTS ONLY FOR OUR FRANKENSTEIN Vue/WebForm pages, to correct styling problems
@Component({
    components: { NavigationSide },
})
export default class NavigationSidePage extends Vue {
    public name = "NavigationSidePage";
    public init = true;

    @Prop({
        type: Object,
    })
    public menuData!: object;

    @Prop({
        type: Number,
    })
    public navigationType!: number;

    public data() {
        return {};
    }
}
